import * as React from 'react'
import Container from '../components/Container'

const about = () => {
    return(
    <Container>
        Hello
    </Container>
    )}

export default about